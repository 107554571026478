import React from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import { DateTime } from 'luxon';
import Obfuscate from 'react-obfuscate';
import { BtnPrimary, HyperlinkPrimary } from './scaffolds';

interface FooterProps {
  logo?: FluidObject;
  dirjenLogo?: FluidObject;
  whatsappLink?: string;
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  return (
    <footer tw="text-white">
      <div tw="bg-blue-black px-4">
        <div tw="container">
          <div tw="grid grid-cols-none lg:grid-cols-4 gap-6 py-12 lg:mx-32">
            <div tw="lg:col-span-2">
              <Img
                alt="Easybiz"
                tw="mb-4"
                fluid={props.logo as FluidObject}
                css={css`
                  width: 130px;
                  @media (max-width: 1024px) {
                    width: 68px;
                  }
                `}
              />
              <p tw="text-sm">
                Easybiz adalah anak perusahaan{' '}
                <HyperlinkPrimary href="https://hukumonline.com">Hukumonline.com</HyperlinkPrimary>{' '}
                yang menawarkan solusi kemudahan, kenyamanan dan legalitas dalam berbisnis di
                Indonesia. Kami memberikan layanan pendirian badan usaha dan perizinan untuk UKM dan
                startup di Indonesia mulai dari proses pendirian PT, domisili, hingga pengurusan
                izin-izin yang diperlukan untuk memulai usaha.
              </p>
              <hr
                css={css`
                  height: 1px;
                  border: none;
                  border-top: 1px dashed white;
                `}
              />
              <div tw="text-sm">
                PT Justika Solusi Indonesia, AD Premier Office Park Lantai 9 Jl. TB Simatupang No. 5
                Ragunan, Pasar Minggu Jakarta <br />
                <div
                  tw="flex flex-wrap"
                  css={css`
                    a {
                      ${tw`text-yellow-bright`}
                      &:hover {
                        ${tw`text-blue-lapis`}
                      }
                    }
                  `}
                >
                  <div>
                    <span tw="mr-1">📞:</span>{' '}
                    <Obfuscate
                      linkText="/"
                      className="gtm-contact-phone"
                      tw="mr-1"
                      tel={'0816-17-369-369'}
                    />{' '}
                  </div>
                  <div>
                    <span tw="mr-1">/</span>{' '}
                    <Obfuscate
                      linkText="/"
                      className="gtm-contact-phone"
                      tw="mr-1"
                      tel={'021-227-089-03'}
                    />{' '}
                    -{' '}
                  </div>
                  <div>
                    <span tw="mr-1">📧:</span>{' '}
                    <Obfuscate
                      linkText="/"
                      className="gtm-contact-email"
                      tw="mr-1"
                      email={'halo@easybiz.id'}
                    />
                  </div>
                </div>
              </div>
              <p>
                Selama pandemi COVID-19, semua konsultasi dan pengiriman dokumen dilakukan secara
                online.
              </p>
              <BtnPrimary
                className="gtm-contact-wa"
                href={props.whatsappLink || '/hubungi-kami-via-whatsapp'}
                target="__blank"
              >
                WhatsApp Kami Di Sini
              </BtnPrimary>
            </div>
            <div>
              <p tw="text-h4 mb-0 font-extrabold font-gilroy">Layanan Easybiz</p>
              <ul
                css={css`
                  padding: 0;
                  margin: 24px 0;
                  li {
                    list-style: none;
                    margin-bottom: 12px;
                  }
                  a {
                    color: white;
                    text-decoration: none;
                    &:hover {
                      color: white;
                      text-decoration: underline;
                    }
                  }
                `}
              >
                <li>
                  <Link to="/layanan/paket-easy-business">Pendirian PT</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-pendirian-pt-perorangan">Pendirian PT Perorangan</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-pendirian-cv">Pendirian CV</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-izin-usaha">Layanan Izin Usaha</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-oss">Layanan OSS RBA</Link>
                </li>
                <li>
                  <a href="https://www.easybiz.id/layanan/paket-iumk/">Paket Izin UMK</a>
                </li>
                <li>
                  <Link to="/layanan/pendirian-yayasan-atau-perkumpulan">Pendirian Yayasan atau Badan Usaha Lain</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-lkpm">Layanan LKPM</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-pma">Penanaman Modal Asing</Link>
                </li>
                <li>
                  <Link to="/layanan/pendaftaran-merek">Pendaftaran Merek</Link>
                </li>
                <li>
                  <Link to="/layanan/paket-virtual-office">Layanan Virtual Office</Link>
                </li>
              </ul>
              <p tw="text-h4 mb-2 font-extrabold font-gilroy">Layanan Pengaduan Konsumen</p>
              <div
                tw="flex"
                css={css`
                  margin-top: 24px;
                `}
              >
                <div tw="mr-4">
                  <Img
                    alt="Direktorat Jendral Perlindungan Konsumen dan Tertib Niaga Kementrian Perdagangan RI"
                    tw="mb-4"
                    fluid={props.dirjenLogo as FluidObject}
                    css={css`
                      width: 50px;
                    `}
                  />
                </div>
                <div
                  css={css`
                    a {
                      ${tw`text-yellow-bright`}
                      &:hover {
                        ${tw`text-blue-lapis`}
                      }
                    }
                  `}
                >
                  <p tw="mb-0 text-b3">
                    Direktorat Jendral Perlindungan <br /> Konsumen dan Tertib Niaga <br />{' '}
                    Kementrian Perdagangan RI
                  </p>
                  <p tw="text-b3">
                    <span tw="mr-1">📞:</span>{' '}
                    <Obfuscate linkText="/" tw="mr-1" tel={'0853-1111-1010'} />
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p tw="text-h4 mb-0 font-extrabold font-gilroy">Bantuan dan Panduan</p>
              <ul
                css={css`
                  padding: 0;
                  margin: 24px 0;
                  li {
                    list-style: none;
                    margin-bottom: 12px;
                  }
                  a {
                    color: white;
                    text-decoration: none;
                    &:hover {
                      color: white;
                      text-decoration: underline;
                    }
                  }
                `}
              >
                <li>
                  <Link to="/tentang-kami/">Tentang Kami</Link>
                </li>
                <li>
                  <a href="https://www.easybiz.id/category/faq/">FAQ</a>
                </li>
                <li>
                  <Link to="/syarat-penggunaan-layanan/">Syarat Penggunaan Layanan</Link>
                </li>
              </ul>
              <p tw="text-h4 mb-8 font-extrabold font-gilroy">Ikuti Kami</p>
              <div
                tw="flex flex-wrap"
                css={css`
                  a {
                    background: white;
                    padding: 12.5px;
                    border-radius: 16px;
                    font-size: 21px;
                    line-height: 21px;
                    ${tw`text-blue-black`}
                    text-decoration: none;
                    display: block;
                    height: 46px;
                    width: 46px;
                    text-align: center;
                    margin-right: 16px;
                    margin-bottom: 16px;
                  }
                `}
              >
                <a
                  href="https://www.facebook.com/easybizID"
                  target="__blank"
                  aria-label="ikuti facebook easybiz"
                >
                  <span className="icon icon-facebook"></span>
                </a>
                <a
                  href="https://www.instagram.com/easybiz.id/?hl=en"
                  target="__blank"
                  aria-label="ikuti instagram easybiz"
                >
                  <span className="icon icon-instagram"></span>
                </a>
                <a
                  href="https://twitter.com/easybizid"
                  target="__blank"
                  aria-label="ikuti twitter easybiz"
                >
                  <span className="icon icon-twitter"></span>
                </a>
                <a
                  href="https://www.linkedin.com/company/easybiz"
                  target="__blank"
                  aria-label="ikuti linkedin easybiz"
                >
                  <span className="icon icon-linkedin"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div tw="bg-blue-black-100 px-4">
        <div tw="container py-10 text-sm">
          <div tw="lg:mx-32">
            Copyright &copy; {DateTime.local().year} Easybiz | All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
